<template>
  <div v-if="currentPlan" class="d-flex align-center flex-wrap justify-center">
    <div v-if="$vuetify.breakpoint.smAndUp">
      <v-icon color="blue-light-2" class="mr-2"> $info</v-icon>
    </div>
    <div class="my-2 text-center">
      <span v-if="onTrial">{{ $trans("trial_period") }}</span>
      <span v-else>{{ $trans("current_plan") }}</span
      >:
      <b>{{ currentPlan.name }}</b>
      <span v-if="planExpiryDate"
        >, {{ $trans("plan_valid_till") }} {{ planExpiryDate }}</span
      >
      <span v-else-if="subscriptionInfo"> ({{ subscriptionInfo }})</span><br />
      <small v-if="!planExpiryDate && currentPeriodEndsAt">
        {{ $trans("subscription_renews_info") }}:
        <b>{{ currentPeriodEndsAt }}</b>
      </small>
      <small
        v-if="
          planExpiryDate && currentPeriodEndsAt && hasActiveStripeSubscription
        "
      >
        {{ $trans("subscription_will_not_renew_info") }}
      </small>
    </div>
    <template
      v-if="loggedUserIsAdmin && !hideCancel && !isCancelingStripeSubscription"
    >
      <div class="ml-4">
        <v-btn
          v-if="!getHasAnyPastDueInvoice"
          color="blue"
          outlined
          @click="cancelPlan"
        >
          {{ $trans("cancel") }}
        </v-btn>
        <v-btn v-else color="blue" outlined @click="openPastDueDialog">
          {{ $trans("cancel") }}
        </v-btn>
      </div>
    </template>
    <template v-if="loggedUserIsAdmin">
      <div v-if="!isCancelingStripeSubscription" class="ml-4">
        <v-btn
          v-if="!getHasAnyPastDueInvoice"
          color="blue"
          outlined
          @click="openPlans"
        >
          <span v-if="onTrial">{{ $trans("select") }}</span>
          <span v-else>{{ $trans("change") }}</span>
        </v-btn>
        <v-btn v-else color="blue" outlined @click="openPastDueDialog">
          {{ $trans("change") }}
        </v-btn>
      </div>
      <div v-else class="ml-4">
        <v-btn
          v-if="!getHasAnyPastDueInvoice"
          color="blue"
          outlined
          @click="goToPlan"
        >
          {{ $trans("select_plan") }}
        </v-btn>
      </div>
    </template>
  </div>
</template>
<script>
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "CurrentPlanInfo",
  mixins: [planActions],
  props: {
    hideCancel: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openPlans() {
      this.$root.$emit("openPlanView", false);
    },
  },
};
</script>
