<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" class="d-flex justify-start align-center">
        <new-app-bar-route
          :title="$trans('dashboard')"
          :subtitle="$trans('section_admin_panel')"
        />
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="12"
        md="6"
        class="d-flex align-center justify-end"
      >
        <v-alert color="accent">
          <current-plan-info hide-cancel />
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewAppBarRoute from "@/views/dashboard/components/AppBar/AppBarComponents/NewAppBarRoute.vue";
import CurrentPlanInfo from "@/views/dashboard/pages/Billing/components/CurrentPlanInfo.vue";

export default {
  name: "AppBarWithPlanInfo",
  components: {
    CurrentPlanInfo,
    NewAppBarRoute,
  },
};
</script>
